.header-container {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
}

.text-field {
  height: 30px;
}

.side-bar {
  display: flex;
}

.side-bar img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

@media (max-width: 789px) {
  .header-container {
    flex-direction: column;
  }
}
