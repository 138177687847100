.sidebar {
  overflow: scroll;
  top: 0;
  left: 0;
  position: fixed;
  min-width: 240px;
  width: 215px;
  /* margin-right: 20px; */
  background: #fff;
  border-right: 1px solid black;
}

.sidebar::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.base-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.view-content {
  max-width: 1640px;
  width: 100%;
  /* flex: 1; */
  /* padding: 20px 28px; */
  margin-left: 240px;
  /* background: #f5f5f5; */
}

.main-content {
  max-width: 1640px;
  width: 100%;
  flex: 1;
  padding: 20px 13px;
  /* margin-left: 225px; */
  /* background: #f5f5f5; */
}

h2 {
  margin-top: 0;
}

/* Specialized for dashboard MEP Services Section*/
ul {
  list-style-type: disc;
  padding-left: 20px;
}

/* Specialized for dashboard MEP Services Section*/
ul ul {
  list-style-type: circle;
}

li {
  margin-bottom: 5px;
}

li:hover {
  cursor: pointer;
  /* background-color: #e6e6e6; */
}

@media (max-width: 780px) {
  .sidebar {
    width: 172px;
    height: 150vh;
    /* position: relative; */
  }

  .main-content {
    margin-left: 225px;
    padding: 20px;
  }
}