.grid-stack {
  width: 100%;
  /* Take full width of the parent */
  height: 100vh;
  /* Full height */
}

.grid-stack-item {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* border: 1px solid red; */
  /* Optional: Debugging border */
}

.grid-stack-item-content {
  /* width: 100%; */
  /* height: auto; */
  /* overflow: hidden; */
  /* border: 1px solid blue; */
  /* Optional: Debugging border */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.grid-stack-item-content-component-key-most_frequently_offered_solutions {
  background: linear-gradient(to bottom, #4a90e2, #1e3a5f);
  /* Use background, not background-color */
  color: white;
  /* Text color */
  padding: 20px;
  /* Adjust padding */
  width: 100%;
  /* Full width */
  margin: auto;
  /* Center horizontally */
  border-radius: 8px;
  /* Optional: Rounded corners */
  text-align: center;
  /* Optional: Center align text */
}